import { FaLocationArrow } from "react-icons/fa";

export function JobPositionSection() {
  return (
    <div
      className="flex justify-center min-h-[700px] bg-dark-300"
      id="job-position"
    >
      <div className="w-11/12 py-20">
        <div className="text-center">
          <h5 className="">Job Position</h5>
          <h2 className="text-5xl font-[500] mt-4">
            <span className="text-primary">Job position</span> that
            <br />I can occopy
          </h2>
        </div>

        <div className="grid grid-cols-12 gap-4 mt-16">
          <Item
            number="01"
            title="Front End Web Developer"
            description="Front End Developer (React JS) with 3+ years of experience building interactive and responsive web applications"
          />
          <Item
            number="02"
            title="Mobile Developer"
            description="Mobile Developer (React Native) with 2+ years of experience building native applications for Android."
          />
          <Item
            number="03"
            title="Backend Developer"
            description="Backend Developer (Express JS) with 1+ years of experience building scalable and secure RESTful APIs."
          />
          <Item
            number="04"
            title="Fullstack Developer"
            description="MERN stack developer with expertise building dynamic and interactive web applications.
"
          />
          <Item
            number="05"
            title="Project Manager"
            description="Project Manager with 2+ years of experience leading software development teams for events & competitions"
          />
          <Item
            number="06"
            title="AI Engineer"
            description="AI Engineer (Tensorflow) with 1 years of experience building and deploying machine learning and deep learning models."
          />
        </div>
      </div>
    </div>
  );
}

function Item({ number, title, description }) {
  return (
    <div className="bg-dark-100 p-8 flex gap-4 lg:col-span-6 col-span-12 rounded-xl justify-between">
      <div>
        <h3 className="text-2xl">{number}</h3>
      </div>
      <div className="text-left grow">
        <h3 className="text-2xl font-[500]">{title}</h3>
        <p className="mt-2">{description}</p>
      </div>
      <div className="flex justify-center items-center">
        <div className="rounded-full p-4 bg-dark-300 flex justify-center items-center hover:text-primary">
          <FaLocationArrow size={14} />
        </div>
      </div>
    </div>
  );
}
