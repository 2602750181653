import imageBase from "src/assets/image/base.png";
import imageMe from "src/assets/image/me.png";

export function MainSection() {
  return (
    <div className="flex justify-center min-h-[700px]" id="main">
      <div className="w-11/12 grid grid-cols-12 gap-4 py-20">
        <div className="lg:col-span-4 col-span-12 text-left flex flex-col justify-center">
          <h3 className="text-4xl text-text">Hello, I'm</h3>
          <h1 className="text-6xl font-bold mt-6 text-primary">
            Alfian <br />
            Prisma Yopiangga
          </h1>
          <h2 className="text-5xl mt-8">
            Software <br />
            Engineer
          </h2>
          <p className="text-lg mt-8">
            I am an experienced software engineer with a passion for building
            innovative digital solutions.
          </p>
          <div className="flex gap-2 mt-4">
            <a
              href="https://drive.google.com/drive/folders/1Yk7ql7p65XrMk_BDqnL8OiKNAX1SXwPn?usp=sharing"
              target="_blank"
              className="bg-primary text-dark-300 font-[500] px-10 py-3 mt-4 hover:bg-dark-100 hover:text-primary duration-200 rounded-xl"
            >
              Download Portfolio
            </a>
          </div>
        </div>
        <div className="lg:col-span-5 relative lg:flex hidden justify-center items-center">
          <img src={imageBase} alt="profile" />
          <div className="absolute rounded-full overflow-hidden -mt-36">
            <img src={imageMe} />
          </div>
        </div>
        <div className="lg:col-span-3 col-span-12 lg:mt-0 mt-10 flex items-center justify-center">
          <div className="bg-dark-300 text-left rounded-xl p-6 w-56">
            <div>
              <h4 className="text-4xl text-primary">3+</h4>
              <p className="mt-1">Years of Experience</p>
              <div className="h-[0.5px] bg-white mt-4"></div>
            </div>
            <div className="mt-4">
              <h4 className="text-4xl text-primary">11</h4>
              <p className="mt-1">Competition</p>
              <div className="h-[0.5px] bg-white mt-4"></div>
            </div>
            <div className="mt-4">
              <h4 className="text-4xl text-primary">3.93/4.0</h4>
              <p className="mt-1">GPA</p>
              <div className="h-[0.5px] bg-white mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
